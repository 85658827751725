.TableRow {
  border-bottom: 1px solid var(--gray-02, rgb(247, 247, 247));
  border-top: 1px solid #fff;
  color: var(--gray-05, rgb(60, 63, 70));
  display: flex;
  font-size: 14px;
  height: 64px;
  justify-content: space-between;

  &.alignLeft {
    justify-content: flex-start;
  }

  padding: 0px 0px 0px 16px;
  &.adminReqRow {
    > div:nth-child(2) {
      @media (max-width: 582px) {
        display: none;
      }
    }
  }
  &.reqRow {
    padding: 0px 0px 0px 16px;
    > div:nth-child(7) {
      @media (max-width: 1206px) {
        display: none;
      }
    }
    > div:nth-child(6) {
      @media (max-width: 1116px) {
        display: none;
      }
    }
    > div:nth-child(3) {
      @media (max-width: 1026px) {
        display: none;
      }
    }
    > div:nth-child(4) {
      @media (max-width: 896px) {
        display: none;
      }
    }
    > div:nth-child(5) {
      @media (max-width: 796px) {
        display: none;
      }
    }
    > div:nth-child(2) {
      @media (max-width: 524px) {
        display: none;
      }
    }
  }
  &.dashboardRow {
    > div:nth-child(3) {
      @media (max-width: 862px) {
        display: none;
      }
    }
    > div:nth-child(2) {
      @media (max-width: 527px) {
        display: none;
      }
    }
  }
  &.decidedRow {
    > div:nth-child(3) {
      @media (max-width: 527px) {
        display: none;
      }
    }
  }
}

.TableRow:hover {
  background-color: var(--seafoam-04, rgb(245, 255, 255));
  border-bottom: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  border-top: 1px solid var(--seafoam-01, rgb(21, 218, 212));
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.highlight {
  background-color: var(--gray-01, rgb(247, 247, 247));
  color: var(--seafoam-02, rgb(0, 191, 186));
}

.disabled {
  background-color: var(--gray-01, rgb(252, 253, 252));
  border-bottom: 1px solid var(--gray-01, rgb(247, 247, 247));
  border-top: 1px solid var(--gray-01, rgb(247, 247, 247));
}

.clickable {
  cursor: pointer;
}
